import React from 'react';
import Routes  from './Routing/Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './Component/Header/Header';
import './App.css'
import MobileFooter from './Component/Header/MobileFooter';
const App = () => {
  return (
   <Router>
    <Header/>
   <Routes/>
   <MobileFooter/>
   </Router>
  );
}

export default App;
