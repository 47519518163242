import React from 'react';
import { Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { VideoCameraOutlined, CodeOutlined, CustomerServiceOutlined, SmileOutlined, BookOutlined, HeartOutlined, DollarOutlined, CameraOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

const categories = [
  { name: 'Gaming', icon: '/images/gaming.png' },
  { name: 'Podcast', icon: '/images/podcast.png' },
  { name: 'Music', icon: '/images/music.png' },
  { name: 'Entertainment', icon: '/images/entertainment.png' },
  { name: 'Tech', icon: '/images/tech.png' },
  { name: 'Facts', icon: '/images/facts.png' },
];

const CategoryItem = ({ name, icon }) => {
  const navigate = useNavigate();
  return (
    <div 
      className="flex flex-col items-center cursor-pointer"
      onClick={() => navigate(`/channels?category=${name}`)}
    >
      <div className="w-32 h-32 bg-gray-100 rounded-full flex items-center justify-center mb-4">
        <span className="text-4xl text-[#F83758]"><img src={icon} alt="" /></span>
      </div>
      <Title level={5} className="m-0 text-center">{name}</Title>
    </div>
  );
};

const FeaturedCategories = () => {
  return (
    <div className="py-12 bg-white">
      <div className="container mx-auto px-4">
        <Title level={2} className="text-center mb-2">
          Explore Popular Categories
        </Title>
        <Paragraph className="text-center text-gray-600 mb-8">
          Discover trending channels across various niches—where content meets passion.
        </Paragraph>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-8 pt-20">
          {categories.map((category, index) => (
            <CategoryItem key={index} {...category} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeaturedCategories;