import React from 'react';
import { useRoutes, Navigate } from 'react-router-dom';

import Stats from '../Component/Stats/Stats';
import FeaturedListings from '../Component/Feature/Feature';
import DetailPageWrapper from '../Component/Channel/DetailPageWrapper';
import CartPage from '../Component/Cart/Cart';
import HomePage from '../Pages/LandingPage/HomePage';
import NotFoundPage from '../Component/PageNotFound/PageNotFound';
import Login from '../Pages/Seller/Login';
import Signup from '../Pages/Seller/SignUp';
import ChannelList from '../Pages/Channels/Channels';
import AdminDashboard from '../Pages/Admin/AdminDashboard';
import SellerPanel from '../Pages/SellerPanel/SellerPanel ';
import UnauthorizedComponent from '../Component/UnAuthorized/UnAuthorized';
import PurchaseSteps from '../Component/Steps/Steps/Steps';
import SellChannelSteps from '../Component/Steps/Steps/SellChannel';
import ChannelTransactionSteps from '../Component/Steps/Steps/ChannelSteps/ChannelSteps';
import BlogSection from '../Pages/Blogs/Blogs';
import UserProfile from '../Component/Profile/Profile';
import GrowYourChannel from '../Pages/GrowYourChannel/GrowYourChannel';
import AboutPage from '../Pages/About/About';
import PaymentGateway from '../Pages/Payment/Payment';
import Privacy from './Privacy';
import PaymentSuccessful from '../Component/Success/Success';

const ProtectedRoute = ({ element, allowedRoles }) => {
  const userRole = localStorage.getItem('role');

  if (!userRole) {
    return <Navigate to="/login" replace />;
  }

  if (allowedRoles.includes(userRole)) {
    return element;
  } else {
    return <Navigate to="/unauthorized" replace />;
  }
};

const Routes = () => {
  return useRoutes([
    { path: '/', element: <HomePage /> },
    { path: '/payment/success', element: <PaymentSuccessful /> },
    { path: '/privacy', element: <Privacy /> },
    { path: '/stats', element: <Stats /> },
    { path: '/feature', element: <FeaturedListings /> },
    { path: '/channel/:id', element: <DetailPageWrapper /> },
    { path: '/how-to', element: <ChannelTransactionSteps /> },
    { path: '/blogs', element: <BlogSection /> },
    { path: '/about', element: <AboutPage /> },
    { path: '/payment-gateway/:channel', element: <PaymentGateway /> },
    {
      path: '/cart',
      element: <ProtectedRoute element={<CartPage />} allowedRoles={['buyer']} />
    },
    {
      path: '/seller-dashboard',
      element: <ProtectedRoute element={<SellerPanel />} allowedRoles={['seller']} />
    },
    { path: '/login', element: <Login /> },
    { path: '/signup', element: <Signup /> },
    { path: '/channels', element: <ChannelList /> },
    { path: '/grow', element: <GrowYourChannel /> },
    {
      path: '/admin-dashboard',
      element: <ProtectedRoute element={<AdminDashboard />} allowedRoles={['admin']} />
    },
    { path: '/unauthorized', element: <UnauthorizedComponent /> },
    { path: '/profile', 
      element:<ProtectedRoute element={<UserProfile />}  allowedRoles={['seller','buyer']} /> 
     },
    { path: '*', element: <NotFoundPage /> },
  ]);
};

export default Routes;