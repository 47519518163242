import React, { useState, useEffect } from 'react';
import { Card, Avatar, Typography, Button, Form, Input, Select, message, Spin } from 'antd';
import { UserOutlined, MailOutlined, LockOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import axios from 'axios';

const { Title, Text } = Typography;
const { Option } = Select;

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';

const StyledCard = styled(Card)`
  max-width: 600px;
  margin: 2rem auto;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
`;

const StyledForm = styled(Form)`
  max-width: 400px;
  margin: 0 auto;
`;

const ColoredButton = styled(Button)`
  background-color: ${props => props.color};
  border-color: ${props => props.color};
  color: white;
  &:hover, &:focus {
    background-color: ${props => props.color};
    border-color: ${props => props.color};
    opacity: 0.8;
  }
`;

const UserProfile = () => {
  const [user, setUser] = useState(null);
  const [isEditing, setIsEditing] = useState(true);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const getAuthHeader = () => {
    const token = localStorage.getItem('token');
    return { 'x-auth-token': token };
  };

  const fetchUserProfile = async () => {
    try {
      const response = await axios.get(`${API_URL}/profile`, {
        headers: getAuthHeader()
      });
      setUser(response.data);
      form.setFieldsValue(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        message.error('Session expired. Please log in again.');
        // Redirect to login page or handle authentication error
      } else {
        message.error('Failed to fetch user profile');
        console.error('Error fetching profile:', error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const response = await axios.put(`${API_URL}/profile`, values, {
        headers: getAuthHeader()
      });
      setUser(response.data);
      message.success('Profile updated successfully');
      setIsEditing(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        message.error('Session expired. Please log in again.');
        // Redirect to login page or handle authentication error
      } else {
        message.error('Failed to update profile');
        console.error('Error updating profile:', error);
      }
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <StyledCard>
        <Spin size="large" />
      </StyledCard>
    );
  }

  if (!user) {
    return (
      <StyledCard>
        <Text>User not found</Text>
      </StyledCard>
    );
  }

  return (
    <div className="mt-40">
      <StyledCard >
        <div className='mt-40' style={{ textAlign: 'center', marginBottom: '2rem' }}>
          <Avatar size={100} icon={<UserOutlined />} style={{ backgroundColor: 'rgb(248, 55, 88)' }} />
          <Title level={2} style={{ marginTop: '1rem', color: 'rgb(248, 55, 88)' }}>{user.name}</Title>
          <Text type="secondary">{user.role}</Text>
        </div>

        {isEditing ? (
          <StyledForm form={form} onFinish={handleSubmit} layout="vertical">
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
              <Input prefix={<UserOutlined />} />
            </Form.Item>
            <Form.Item name="email" label="Email">
              <Input prefix={<MailOutlined />} disabled />
            </Form.Item>

            <Form.Item name="password" label="New Password">
              <Input.Password prefix={<LockOutlined />} />
            </Form.Item>
            <Form.Item name="role" label="Role">
              <Select  disabled>
                <Option value="user">User</Option>
                <Option value="admin">Admin</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <ColoredButton type="primary" htmlType="submit" color="rgb(248, 55, 88)" style={{ marginRight: '1rem' }}>
                Save Changes
              </ColoredButton>
              <Button onClick={() => setIsEditing(false)}>Cancel</Button>
            </Form.Item>
          </StyledForm>
        ) : (
          <>
            <p><strong>Email:</strong> {user.email}</p>
            <p><strong>Role:</strong> {user.role}</p>

          </>
        )}
      </StyledCard>
    </div>
  );
};

export default UserProfile;