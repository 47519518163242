import React from 'react';
import { FaFacebookF, FaPinterestP, FaInstagram, FaTwitter } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-gray-100 pt-16 pb-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          <div className="col-span-1 md:col-span-2">
            <h2 className="text-2xl font-bold mb-4">Palkar Shubham Sudam</h2>
            <p className="text-gray-600 mb-2">Nruhsingh Nivas, Shrungeri Colony</p>
            <p className="text-gray-600 mb-2">Pangari Road, Beed</p>
            <p className="text-gray-600 mb-2">+91 8010803291</p>
            <p className="text-gray-600">4spalkarbusiness@gmail.com </p>
            <p className="text-gray-600">shubham@socialswap.in</p>
            

          </div>
          
          {/* <div>
            <h3 className="text-lg font-semibold mb-4">COMPANY</h3>
            <ul className="space-y-2">
              <li><a href="#" className="text-gray-600 hover:text-gray-900">About Us</a></li>
              <li><a href="#" className="text-gray-600 hover:text-gray-900">Contact</a></li>
              <li><a href="#" className="text-gray-600 hover:text-gray-900">Shipping & Return</a></li>
              <li><a href="#" className="text-gray-600 hover:text-gray-900">FAQ</a></li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-lg font-semibold mb-4">INFORMATION</h3>
            <ul className="space-y-2">
              <li><a href="#" className="text-gray-600 hover:text-gray-900">My Account</a></li>
              <li><a href="#" className="text-gray-600 hover:text-gray-900">Login</a></li>
              <li><a href="#" className="text-gray-600 hover:text-gray-900">My Cart</a></li>
              <li><a href="#" className="text-gray-600 hover:text-gray-900">Wishlist</a></li>
              <li><a href="#" className="text-gray-600 hover:text-gray-900">Checkout</a></li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-lg font-semibold mb-4">CONTACT</h3>
            <ul className="space-y-2">
              <li><a href="#" className="text-gray-600 hover:text-gray-900">Customer Service</a></li>
              <li><a href="#" className="text-gray-600 hover:text-gray-900">Store Locator</a></li>
              <li><a href="#" className="text-gray-600 hover:text-gray-900">Wholesale</a></li>
              <li><a href="#" className="text-gray-600 hover:text-gray-900">Career</a></li>
            </ul>
          </div> */}
        </div>
        
        <div className="mt-12 pt-8 border-t border-gray-200">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-gray-600 mb-4 md:mb-0">&copy; Shubham Palkar 2024</p>
            <div className="flex space-x-4">
              <a href="#" className="text-gray-400 hover:text-gray-600"><FaFacebookF /></a>
              <a href="#" className="text-gray-400 hover:text-gray-600"><FaPinterestP /></a>
              <a href="#" className="text-gray-400 hover:text-gray-600"><FaInstagram /></a>
              <a href="#" className="text-gray-400 hover:text-gray-600"><FaTwitter /></a>
            </div>
            {/* <div className="flex space-x-2 mt-4 md:mt-0">
              <img src="/path-to-amex-logo.png" alt="Amex" className="h-6" />
              <img src="/path-to-gpay-logo.png" alt="Google Pay" className="h-6" />
              <img src="/path-to-mastercard-logo.png" alt="Mastercard" className="h-6" />
              <img src="/path-to-paypal-logo.png" alt="PayPal" className="h-6" />
              <img src="/path-to-shopify-pay-logo.png" alt="Shop Pay" className="h-6" />
              <img src="/path-to-venmo-logo.png" alt="Venmo" className="h-6" />
            </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;