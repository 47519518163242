import React, { useState, useEffect } from 'react';
import { Card, Typography, Tag, Tooltip, Row, Col, Modal } from 'antd';
import { HeartOutlined, ShoppingCartOutlined, EyeOutlined, DeleteOutlined, ShareAltOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import axiosInstance, { api, url } from '../../API/api';
import styled from 'styled-components';
import { 
    FacebookShareButton, 
    TwitterShareButton, 
    WhatsappShareButton, 
    LinkedinShareButton,
    TelegramShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    LinkedinIcon,
    TelegramIcon
} from 'react-share';

const { Text } = Typography;
const MotionCard = motion(Card);

// Existing styled components remain the same...
const StyledCard = styled(MotionCard)`
  width: 99%;
  margin:auto;
  border-radius: 8px;
  overflow: hidden;
  .ant-card-body {
    padding: 12px;
  }
  .ant-card-cover {
    position: relative;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 250px;
  object-fit: contain;
`;

const DiscountTag = styled(Tag)`
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #ff4d4f;
  color: white;
  border: none;
  padding: 2px 8px;
  border-radius: 4px;
`;

const StockTag = styled(Tag)`
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: gray;
  color: white;
  border: none;
  padding: 2px 8px;
  border-radius: 4px;
`;

const ActionButtons = styled.div`
  position: absolute;
  top: 50px;
  right: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ActionButton = styled.button`
  background-color: white;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  &:hover {
    background-color: #f0f0f0;
  }
`;

// New styled components for share modal
const ShareModalContent = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 20px;
  .share-button {
    cursor: pointer;
    transition: transform 0.2s;
    &:hover {
      transform: scale(1.1);
    }
  }
`;

const Price = styled(Text)`
  font-size: 18px;
  font-weight: bold;
`;

const OriginalPrice = styled(Text)`
  text-decoration: line-through;
  color: #999;
  margin-left: 8px;
`;

const Rating = styled.div`
  color: #faad14;
  font-size: 14px;
`;

const ChannelCard = ({ channel, updateCartStatus }) => {
    const [isInCart, setIsInCart] = useState(false);
    const [isShareModalVisible, setIsShareModalVisible] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        checkCartStatus();
    }, [channel._id]);

    const checkCartStatus = async () => {
        if (!localStorage.getItem('token') || localStorage.getItem('role') !== 'buyer') {
            return;
        }
        try {
            const response = await axiosInstance.get(`${api}/cart`);
            const cartItems = response.data.channels;
            setIsInCart(cartItems.some(item => item._id === channel._id));
        } catch (err) {
            console.error('Failed to check cart status', err);
        }
    };

    const handleAddToCart = async (e) => {
        e.stopPropagation();
        if (!localStorage.getItem('token') || localStorage.getItem('role') !== 'buyer') {
            return navigate('/login');
        }
        try {
            if (isInCart) {
                await axiosInstance.delete(`${api}/cart/remove/${channel._id}`);
                setIsInCart(false);
            } else {
                await axiosInstance.post(`${api}/cart/add`, {
                    channelId: channel._id,
                    quantity: 1
                });
                setIsInCart(true);
            }
            if (updateCartStatus) {
                updateCartStatus();
            }
        } catch (err) {
            console.error('Error updating cart', err);
        }
    };

    const handleViewDetails = (e) => {
        e.stopPropagation();
        navigate(`/channel/${channel._id}`);
    };

    const handleShare = (e) => {
        e.stopPropagation();
        setIsShareModalVisible(true);
    };

    const shareUrl = `${window.location.origin}/channel/${channel._id}`;
    const shareTitle = `Check out this YouTube channel: ${channel.name}`;
    const shareDescription = `${channel.description.substring(0, 100)}...`;

    return (
        <>
            <StyledCard
                onClick={handleViewDetails}
                hoverable
                cover={
                    <div style={{ position: 'relative'}}>
                        <StyledImage alt={channel.name} src={channel.bannerUrl ? url+channel.bannerUrl : '/images/yt.png'} />
                        <StockTag>Premium</StockTag>
                        <span></span>
                        <ActionButtons>
                            {/* <Tooltip title="Add to Wishlist">
                                <ActionButton><HeartOutlined /></ActionButton>
                            </Tooltip> */}
                            <Tooltip title={isInCart ? "Remove from Cart" : "Add to Cart"}>
                                <ActionButton onClick={handleAddToCart}>
                                    {!isInCart ? <ShoppingCartOutlined /> : <DeleteOutlined style={{color:'red'}}/>}
                                </ActionButton>
                            </Tooltip>
                            <Tooltip title="View Details">
                                <ActionButton onClick={handleViewDetails}><EyeOutlined /></ActionButton>
                            </Tooltip>
                            <Tooltip title="Share">
                                <ActionButton onClick={handleShare}><ShareAltOutlined /></ActionButton>
                            </Tooltip>
                        </ActionButtons>
                    </div>
                }
            >
                <Text strong>{channel.name}</Text>
                <div>
                    <Price>${channel.estimatedEarnings.toLocaleString()}</Price>
                    <OriginalPrice>${(channel.estimatedEarnings * 1.2).toFixed(2)}</OriginalPrice>
                    <div className='font-semibold '>Subscribers: {channel.subscriberCount}</div>
                    <div className='font-semibold '>Total views: {channel.viewCount}</div>
                    <div className='font-semibold '>Estimated Earnings: {channel.estimatedEarnings}</div>
                    <div className='font-semibold text-clip overflow-hidden'>Description: {channel.description}</div>
                </div>
                <Rating>★★★★★</Rating>
            </StyledCard>

            <Modal
                title="Share This Channel"
                open={isShareModalVisible}
                onCancel={(e) => {
                    e.stopPropagation();
                    setIsShareModalVisible(false);
                }}
                footer={null}
            >
                <ShareModalContent onClick={e => e.stopPropagation()}>
                    <FacebookShareButton
                        url={shareUrl}
                        quote={shareTitle}
                        hashtag="#YouTubeChannel"
                        className="share-button"
                    >
                        <FacebookIcon size={40} round />
                    </FacebookShareButton>

                    <TwitterShareButton
                        url={shareUrl}
                        title={shareTitle}
                        className="share-button"
                    >
                        <TwitterIcon size={40} round />
                    </TwitterShareButton>

                    <WhatsappShareButton
                        url={shareUrl}
                        title={shareTitle}
                        className="share-button"
                    >
                        <WhatsappIcon size={40} round />
                    </WhatsappShareButton>

                    <LinkedinShareButton
                        url={shareUrl}
                        title={shareTitle}
                        summary={shareDescription}
                        className="share-button"
                    >
                        <LinkedinIcon size={40} round />
                    </LinkedinShareButton>

                    <TelegramShareButton
                        url={shareUrl}
                        title={shareTitle}
                        className="share-button"
                    >
                        <TelegramIcon size={40} round />
                    </TelegramShareButton>
                </ShareModalContent>
            </Modal>
        </>
    );
};

export default ChannelCard;