import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import DetailPage from './DetailPage';
import { useParams } from 'react-router-dom';

const DetailPageWrapper = () => {
    const [cart, setCart] = useState([]);
    const { id } = useParams();
    const channel = []?.find((item) => item?.id === id);

    useEffect(() => {
        // Load cart from localStorage when component mounts
        const savedCart = JSON.parse(localStorage.getItem('cart') || '[]');
        setCart(savedCart);
    }, []);

    const handleAddToCart = (channel) => {
        const updatedCart = [...cart];
        const channelIndex = updatedCart.findIndex(item => item.id === channel.id);

        if (channelIndex === -1) {
            // If the channel is not in the cart, add it
            updatedCart.push(channel);
            message.success(`${channel.name} has been added to your cart!`);
        } else {
            // If the channel is already in the cart, remove it
            updatedCart.splice(channelIndex, 1);
            message.success(`${channel.name} has been removed from your cart!`);
        }

        setCart(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
    };

    const isInCart = cart.some(item => item?._id === channel?._id);

    return (
        <div>
            <h1 className="text-3xl font-bold text-center my-8">YouTube Channel Details</h1>
            <DetailPage
                channel={channel}
                onAddToCart={handleAddToCart}
                isInCart={isInCart}
            />
        </div>
    );
};

export default DetailPageWrapper;