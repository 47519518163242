import React from 'react';
import { Card, Typography } from 'antd';

const { Title, Paragraph } = Typography;

const testimonials = [
    {
        name: "John Doe",
        role: "YouTube Creator",
        content: "This marketplace made selling my channel a breeze. I got a fair price and the process was smooth!"
    },
    {
        name: "Jane Smith",
        role: "Channel Investor",
        content: "I've bought multiple channels here. The variety and quality of channels available is impressive."
    },
    {
        name: "Mike Johnson",
        role: "Content Creator",
        content: "As a beginner, I found the perfect channel to kickstart my YouTube career. Highly recommended!"
    }
];

const TestimonialCard = ({ name, role, content }) => (
    <Card className="h-full shadow-md hover:shadow-lg transition-shadow duration-300">
        <div className="flex flex-col h-full justify-between items-center text-center">
            <img src="/images/user.jpg" className='object-contain w-24 h-24 rounded-full mb-4' alt={name} />
            <div>
                <Title level={4} className="mb-1">{name}</Title>
                <Paragraph className="text-gray-500 mb-4">{role}</Paragraph>
                <Paragraph className="text-lg italic">{content}</Paragraph>
            </div>
        </div>
    </Card>
);

const Testimonials = () => (
    <div className="py-16 ">
        <div className="container mx-auto px-4">
            <Title level={2} className="text-center mb-12 text-[#F83758]">
                What Our Users Say
            </Title>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {testimonials.map((testimonial, index) => (
                    <TestimonialCard key={index} {...testimonial} />
                ))}
            </div>
        </div>
    </div>
);

export default Testimonials;