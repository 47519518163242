import React, { useState, useEffect } from 'react';
import { Layout, Input, List, Typography, Select, DatePicker, Switch, Row, Col, Button, Drawer, message, Checkbox } from 'antd';
import { SearchOutlined, MenuOutlined, ClearOutlined } from '@ant-design/icons';
import axiosInstance, { api } from '../../API/api';
import moment from 'moment';
import ChannelCard from '../../Component/Card/Card';
import styled from 'styled-components';
import { useParams, useSearchParams } from 'react-router-dom';

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const StyledLayout = styled(Layout)`
  .ant-layout-sider {
    @media (max-width: 1024px) {
      display: none;
    }
  }
`;

const StyledContent = styled(Content)`
  transition: margin-left 0.2s;
  background:white;
  overflow:hidden;
  @media (min-width: 1025px) {
   
  }
`;

const MobileFilterButton = styled(Button)`
  @media (min-width: 1025px) {
    display: none;
  }
`;

const ChannelList = () => {
    const [channels, setChannels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filterSelected, setFilterSelected] = useState(false);
    const [mobileDrawerVisible, setMobileDrawerVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [params,] = useSearchParams()
    const [filteredChannels, setFilteredChannels] = useState([]);
    
    const [filters, setFilters] = useState({
        channelName: '',
        category: [],
        subscriberRange: [],
        viewCountRange: [],
        videoCountRange: [],
        earningsRange: [],
        joinedDateRange: [null, null],
        country: [],
        averageViewsRange: [],
        language: [],
        channelType: [],
        contentType: [],
        recentViewsRange: [],
        copyrightStrike: null,
        communityStrike: null,
        monetized: null,
        watchTimeRange: [],
    });

    useEffect(() => {
        fetchChannels();
    }, [filters, filterSelected]);

    useEffect(() => {
        if (channels.length > 0) {
            const filtered = channels.filter(channel =>
                channel.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredChannels(filtered);
        }
    }, [searchTerm, channels]);
    const handleSearch = (value) => {
        setSearchTerm(value);
    };
    const fetchChannels = async () => {
        try {
            setLoading(true);
            let params = new URLSearchParams();

            if (filterSelected) {
                Object.entries(filters).forEach(([key, value]) => {
                    if (Array.isArray(value) && value.length > 0) {
                        params.append(key, JSON.stringify(value));
                    } else if (value !== null && value !== '') {
                        params.append(key, JSON.stringify(value));
                    }
                });
            } else {
                params.append('filters', null);
            }

            const response = await axiosInstance.get(`${api}/channels`, { params });

            if (response.data && Array.isArray(response.data.channels)) {
                setChannels(response.data.channels);
            } else {
                console.error('Unexpected API response structure:', response.data);
                message.error('Error fetching channels. Please try again later.');
                setChannels([]);
            }
        } catch (error) {
            console.error('Error fetching channels:', error);
            message.error('Error fetching channels. Please try again later.');
            setChannels([]);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (name, value) => {
        setFilters(prevFilters => ({ ...prevFilters, [name]: value }));

        setFilterSelected(() => {
            const updatedFilters = { ...filters, [name]: value };
            return Object.values(updatedFilters).some(val =>
                (Array.isArray(val) && val.length > 0) ||
                (val !== null && val !== '')
            );
        });
    };

    const clearFilters = () => {
        setFilters({
            channelName: '',
            category: [],
            subscriberRange: [],
            viewCountRange: [],
            videoCountRange: [],
            earningsRange: [],
            joinedDateRange: [null, null],
            country: [],
            averageViewsRange: [],
            language: [],
            channelType: [],
            contentType: [],
            recentViewsRange: [],
            copyrightStrike: null,
            communityStrike: null,
            monetized: null,
            watchTimeRange: [],
        });
        setFilterSelected(false);
    };

    const renderFilter = (title, filterName, component) => (
        <div style={{ marginBottom: '20px' }}>
            <Title level={5}>{title}</Title>
            {component}
        </div>
    );

    const renderRangeCheckboxes = (filterName, options) => (
        <Checkbox.Group
            value={filters[filterName].map(range => JSON.stringify(range))}
            onChange={(checkedValues) => {
                const newRanges = checkedValues.map(value => JSON.parse(value));
                handleInputChange(filterName, newRanges);
            }}
        >
            <Row>
                {options.map((option, index) => (
                    <Col span={24} key={index}>
                        <Checkbox value={JSON.stringify(option.value)}>{option.label}</Checkbox>
                    </Col>
                ))}
            </Row>
        </Checkbox.Group>
    );

    const FiltersContent = () => (
        <div style={{ padding: '20px', background: 'white' }}>
            <Row justify="space-between" align="middle" style={{ marginBottom: '20px' }}>
                <Col>
                    <Title level={4} style={{ color: '#', margin: 0 }}>FILTERS</Title>
                </Col>
                <Col>
                    <Button
                        icon={<ClearOutlined />}
                        onClick={clearFilters}
                        style={{ color: '#333', borderColor: '#bbb' }}
                    >
                        Clear
                    </Button>
                </Col>
            </Row>



            {renderFilter("Category", "category",
                <Select mode="multiple" style={{ width: '100%' }} value={filters.category} onChange={(value) => handleInputChange("category", value)}>
                    <Option value="Gaming">Gaming</Option>
                    <Option value="Vlogs">Vlogs</Option>
                    <Option value="Music">Music</Option>
                    <Option value="Tech">Tech</Option>
                    <Option value="Facts">Facts</Option>
                    <Option value="Entertainment">Entertainment</Option>
                    <Option value="Anime">Anime</Option>
                    <Option value="Education">Education</Option>
                    <Option value="Podcast">Podcast</Option>
                    <Option value="News">News</Option>
                    <Option value="Commentary">Commentary</Option>
                    <Option value="Reaction">Reaction</Option>
                    <Option value="Other">Other</Option>
                </Select>
            )}

            {renderFilter("Channel Type", "channelType",
                <Select mode="multiple" style={{ width: '100%' }} value={filters.channelType} onChange={(value) => handleInputChange("channelType", value)}>
                    <Option value="Long Videos">Long Videos</Option>
                    <Option value="Short Videos">Short Videos</Option>
                    <Option value="Both Long & Short Videos">Mixed</Option>
                </Select>
            )}

            {renderFilter("Subscriber Count", "subscriberRange",
                renderRangeCheckboxes("subscriberRange", [
                    { value: [0, 1000], label: '0 to 1k' },
                    { value: [1001, 10000], label: '1k to 10k' },
                    { value: [10001, 50000], label: '10k to 50k' },
                    { value: [50001, 100000], label: '50k to 100k' },
                    { value: [100001, 1000000000000000000000000], label: 'Above 100k' },
                ])
            )}

            {renderFilter("Total View Count", "viewCountRange",
                renderRangeCheckboxes("viewCountRange", [
                    { value: [0, 1000], label: '0 to 1k' },
                    { value: [1001, 10000], label: '1k to 10k' },
                    { value: [10001, 50000], label: '10k to 50k' },
                    { value: [50001, 100000], label: '50k to 100k' },
                    { value: [100001, 1000000000000000000000000], label: 'Above 100k' },
                ])
            )}

            {renderFilter("Video Count", "videoCountRange",
                renderRangeCheckboxes("videoCountRange", [
                    { value: [0, 10], label: '0 to 10' },
                    { value: [11, 50], label: '11 to 50' },
                    { value: [51, 100], label: '51 to 100' },
                    { value: [101, 500], label: '101 to 500' },
                    { value: [501, 1000000000000000000000000], label: 'Above 500' },
                ])
            )}

            {renderFilter("Estimated Monthly Earnings ($)", "earningsRange",
                renderRangeCheckboxes("earningsRange", [
                    { value: [0, 100], label: '0 to $100' },
                    { value: [10, 1000], label: '$10 to $1k' },
                    { value: [1001, 5000], label: '$1k to $5k' },
                    { value: [5001, 10000], label: '$5k to $10k' },
                    { value: [10001, 100000000000000000000], label: 'Above $10k' },
                ])
            )}

            {renderFilter("Joined Date", "joinedDateRange",
                <DatePicker.RangePicker
                    value={filters.joinedDateRange}
                    onChange={(dates) => handleInputChange("joinedDateRange", dates)}
                />
            )}

            {renderFilter("Country", "country",
                <Select mode="multiple" style={{ width: '100%' }} value={filters.country} onChange={(value) => handleInputChange("country", value)}>
                    <Option value="US">United States</Option>
                    <Option value="UK">United Kingdom</Option>
                    <Option value="CA">Canada</Option>
                </Select>
            )}

            {renderFilter("Average Views Per Video", "averageViewsRange",
                renderRangeCheckboxes("averageViewsRange", [
                    { value: [0, 1000], label: '0 to 1k' },
                    { value: [1001, 10000], label: '1k to 10k' },
                    { value: [10001, 50000], label: '10k to 50k' },
                    { value: [50001, 100000], label: '50k to 100k' },
                    { value: [100001, 1000000000000000000000000], label: 'Above 100k' },
                ])
            )}

            {renderFilter("Language", "language",
                <Select mode="multiple" style={{ width: '100%' }} value={filters.language} onChange={(value) => handleInputChange("language", value)}>
                    <Option value="en">English</Option>
                    <Option value="es">Spanish</Option>
                    <Option value="fr">French</Option>
                </Select>
            )}

            {renderFilter("Recent Views (Last 28 Days)", "recentViewsRange",
                renderRangeCheckboxes("recentViewsRange", [
                    { value: [0, 1000], label: '0 to 1k' },
                    { value: [1001, 10000], label: '1k to 10k' },
                    { value: [10001, 50000], label: '10k to 50k' },
                    { value: [50001, 100000], label: '50k to 100k' },
                    { value: [100001, 1000000000000000000000000], label: 'Above 100k' },
                ])
            )}

            {renderFilter("Watch Time (Hours)", "watchTimeRange",
                renderRangeCheckboxes("watchTimeRange", [
                    { value: [0, 1000], label: '0 to 1k' },
                    { value: [1001, 10000], label: '1k to 10k' },
                    { value: [10001, 50000], label: '10k to 50k' },
                    { value: [50001, 100000], label: '50k to 100k' },
                    { value: [100001, 1000000000000000000000000], label: 'Above 100k' },
                ])
            )}

            {/* {renderFilter("Copyright Strike", "copyrightStrike",
                <Switch checked={filters.copyrightStrike} onChange={(checked) => handleInputChange("copyrightStrike", checked)} />
            )}

            {renderFilter("Community Strike", "communityStrike",
                <Switch checked={filters.communityStrike} onChange={(checked) => handleInputChange("communityStrike", checked)} />
            )} */}

            {renderFilter("Monetized", "monetized",
                <Switch checked={filters.monetized} onChange={(checked) => handleInputChange("monetized", checked)} />
            )}
        </div>
    );

    return (
        <div className="bg-white mt-16 mb-16">
            <StyledLayout>
                <div className='flex'>
                    <div className='w-[400px] hidden sm:flex'>
                        <FiltersContent />
                    </div>
                    <Drawer
                        title="Filters"
                        placement="left"
                        closable={true}
                        onClose={() => setMobileDrawerVisible(false)}
                        visible={mobileDrawerVisible}
                        bodyStyle={{ padding: 0 }}
                        width={300}
                    >
                        <FiltersContent />
                    </Drawer>

                    <StyledContent>
                        <Row gutter={[16, 16]} align="middle" style={{ marginBottom: '20px', marginLeft: '0' }} className='my-4 px-8 bg-white'>
                            <Col xs={24} sm={24} md={16} lg={18}>
                                <Input
                                    placeholder="Search channels"
                                    prefix={<SearchOutlined />}
                                    value={searchTerm}
                                    onChange={(e) => handleSearch(e.target.value)}
                                    size="large"
                                />
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={6}>
                                <MobileFilterButton
                                    type="primary"
                                    icon={<MenuOutlined />}
                                    onClick={() => setMobileDrawerVisible(true)}
                                    size="large"
                                    block
                                    className='w-50'
                                >
                                    Filters
                                </MobileFilterButton>
                            </Col>
                        </Row>

                        <List
                            style={{ marginLeft: '0' }}
                            grid={{ gutter: 24, xs: 1, sm: 2, md: 2, lg: 3, xl: 3, xxl: 4 }}
                            dataSource={channels}
                            loading={loading}
                            className=' bg-white'
                            renderItem={channel => (
                                <List.Item>
                                    <ChannelCard channel={channel} />
                                </List.Item>
                            )}
                        />
                        {channels.length === 0 && !loading && (
                            <div style={{ textAlign: 'center', margin: '20px 0' }}>
                                No channels found. Try adjusting your filters.
                            </div>
                        )}
                    </StyledContent>

                </div>
            </StyledLayout>
        </div>
    );
};

export default ChannelList;